import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { ContextProvider } from './Context/ContextProvider'
import './index.css'
// import 'react-phone-input-2/lib/material.css'
import App from './App'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { InstitutionContextProvider } from './Context/InstitutionContextProvider'

console.log(process.env.REACT_APP_STAGE)

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_STAGE === 'PROD' ? 'us-east-1' : 'us-east-2',
    userPoolId:
      process.env.REACT_APP_STAGE === 'PROD'
        ? 'us-east-1_a3Fk5S3hh'
        : 'us-east-2_L3E5BSjIf',
    identityPoolId:
      process.env.REACT_APP_STAGE === 'PROD'
        ? 'us-east-1:a68cac30-d7f7-4f73-9b1f-ca6a4f86eba6'
        : 'us-east-2:9b1fda39-3231-4606-b32f-7ba24edcb53d',
    userPoolWebClientId:
      process.env.REACT_APP_STAGE === 'PROD'
        ? 'jevucp6v2ehehqerq0rlgn4d8'
        : '5pqia04a65b3ef6fbmpoccj4vl',
    oauth: {
      // domain: `${"gymnaism-harshnew-auth-domain.auth.ap-south-1.amazoncognito.com"}`,
      // scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      // redirectSignIn: "http://localhost:3000",
      // redirectSignOut: "http://localhost:3000",
      // redirectSignIn: "https://gymnaism.netlify.app",
      // redirectSignOut: "https://gymnaism.netlify.app",
      responseType: 'token'
    }
  },
  Storage: {
    region: 'us-east-1',
    bucket: 'institution-utils',
    identityPoolId:
      process.env.REACT_APP_STAGE === 'PROD'
        ? 'us-east-1:a68cac30-d7f7-4f73-9b1f-ca6a4f86eba6'
        : 'us-east-2:9b1fda39-3231-4606-b32f-7ba24edcb53d'
  },
  API: {
    endpoints: [
      {
        name: 'clients',
        endpoint:
          process.env.REACT_APP_STAGE === 'PROD'
            ? 'https://er9zh7i7md.execute-api.us-east-1.amazonaws.com/dev'
            : 'https://i8k00gfjyf.execute-api.us-east-2.amazonaws.com/dev',
        region:
          process.env.REACT_APP_STAGE === 'PROD' ? 'us-east-1' : 'us-east-2'
      },
      {
        name: 'user',
        endpoint:
          process.env.REACT_APP_STAGE === 'PROD'
            ? 'https://xc5rnclqr6.execute-api.us-east-1.amazonaws.com/dev'
            : 'https://r5dp21mb28.execute-api.us-east-2.amazonaws.com/dev',
        // 'https://7sh8wrjmm2.execute-api.us-east-1.amazonaws.com/dev',
        region:
          process.env.REACT_APP_STAGE === 'PROD' ? 'us-east-1' : 'us-east-2'
      },
      {
        name: 'institution',
        endpoint: 'https://xc5rnclqr6.execute-api.us-east-1.amazonaws.com/dev/',
        region: 'us-east-1'
      }
    ]
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <InstitutionContextProvider>
      <ContextProvider>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          bodyClassName="toastBody"
        />
      </ContextProvider>
    </InstitutionContextProvider>
  </BrowserRouter>
)
